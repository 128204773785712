import type { Editor, JSONContent } from "@tiptap/core";
import { stAnalytics } from "@repo/analytics";
import { Recipient, type threads } from "@repo/client";
import { Named } from "@repo/logger";
import { getContentTypes } from "@repo/mime";
import { useNavigate } from "@solidjs/router";
import {
  type Accessor,
  type ParentComponent,
  type Setter,
  createContext,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  useContext,
} from "solid-js";
import { type SetStoreFunction, createStore, unwrap } from "solid-js/store";
import { createEditor } from "tiptap-solid";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import {
  selectIsIdentityConnecting,
  useIsIdentityConnecting,
} from "~/domains/identity/hooks";
import {
  WithLimitedFileTypes,
  WithMultipleFiles,
  useAssetUploader,
} from "~/domains/projects/hooks/assetUpload";
import {
  blankKnowledge,
  newPromptWithDefaults,
  newTransformationPrompt,
} from "~/domains/threads/prompt/prompt";
import { urls } from "~/lib/urls";
import { type Wire, useWire } from "~/wire";
import type { PromptProps } from "./Prompt";
import { ChatFileUpload } from "./components/ChatFileUpload";
import { getEditorExtensions } from "./extensions/getExtensions";
import { getPromptTextAndDecorations } from "./getPromptTextAndDecorations";
export type PromptSettings = {
  submitKeybinding: "enter" | "shift+enter";
};
import { transformPastedHTML, transformPastedText } from "./pasteTransform";

export const highlights = ["send-button"] as const;
export type HighlightId = (typeof highlights)[number];

type PromptContextValue = {
  changeKnowledge: () => Promise<void>;
  editor: Accessor<Editor | null>;
  focused: Accessor<boolean>;
  highlights: Accessor<HighlightId[]>;
  isConnecting: Accessor<boolean>;
  promptRef: Accessor<HTMLElement | undefined>;
  promptSettings: PromptSettings;
  rawProps: PromptProps;
  removeHighlight: (h: HighlightId) => void;
  addHighlight: (h: HighlightId) => void;
  setFocused: Setter<boolean>;
  setPromptRef: Setter<HTMLElement | undefined>;
  setPromptSettings: SetStoreFunction<PromptSettings>;
  /**
   * Types text into the chat UI one letter at a type, creating a typing effect
   *
   * If you don't need to show the typing effect and just submit a prompt immediately, use `submitPrompt` instead and pass a `customPrompt`
   * @param text Text to write
   * @param instant If the prompt gets typed out or instantly appears in the UI
   * @returns
   */
  typeText: (text: string, instant?: boolean) => Promise<void>;
  submitPrompt: (
    /**
     * Prompts can be submitted either from the prompt chat box UI or with a custom prompt.
     * Leave `customPrompt` undefined if you want the prompt to be picked up from the prompt UI.
     */
    customPrompt?: {
      text: string;
      mentionedAssets: string[];
    },
  ) => Promise<void>;
  uploader: ReturnType<typeof useAssetUploader>;
  promptPositioning: Accessor<
    | {
        x: number;
        y: number;
      }
    | undefined
  >;
  setPromptPositioning: Setter<
    | {
        x: number;
        y: number;
      }
    | undefined
  >;
  showUploadModal: Accessor<boolean>;
  setShowUploadModal: Setter<boolean>;
};

const PromptContext = createContext<PromptContextValue>();

export const usePromptContext = () => {
  const ctx = useContext(PromptContext);
  if (!ctx) throw Error("No PromptContext found");
  return ctx;
};

const useAssetUploaderWithDiffs = (wire: Wire) => {
  const uploader = useAssetUploader(
    {
      logger: wire.dependencies.logger,
      wire,
    },
    WithMultipleFiles(),
    WithLimitedFileTypes(getContentTypes()),
  );

  const generateDiff = (referencedOutsideUploader?: {
    added: string[];
    removed: string[];
  }) => {
    // Calculate any changes to our knowledge base
    const current = uploader
      .assets()
      .filter((a) => a.snapshot.value === "Done")
      .map((a) => a.snapshot.context.assetID)
      .filter((a) => a) as string[];

    referencedOutsideUploader?.added?.forEach((a) => {
      if (!current.includes(a)) current.push(a);
    });

    const addedAssets = current;
    const removedAssets: string[] = referencedOutsideUploader?.removed || [];
    const useWorldKnowledge = current.length === 0;

    return {
      addedAssets,
      removedAssets,
      useWorldKnowledge,
    };
  };

  return {
    uploader,
    generateDiff,
  };
};

const LAST_PROMPT_KEY = "last-prompt:v1";

export const PromptContextProvider: ParentComponent<PromptProps> = (props) => {
  const wire = useWire();
  const logger = new Named(wire.dependencies.logger, "PersistentPrompt");
  const navigate = useNavigate();
  const isConnecting = useIsIdentityConnecting();
  const { threadEventProps } = useThreadEventProperties();

  const { uploader, generateDiff } = useAssetUploaderWithDiffs(wire);

  const [showUploadModal, setShowUploadModal] = createSignal(false);

  // allowNavigate is a signal that is set to false when we're in a thread, and true when we're not. The true
  // signal will be sent in our onSubmit once we have successfully submitted a prompt. This helps us assert that the
  // prompt has been processed so we don't lose any data on the navigation when we enter the `resume` state.
  const [allowNavigate, setAllowNavigate] = createSignal(
    !wire.services.threads.snapshot?.context?.threadId,
  );
  const [promptRef, setPromptRef] = createSignal<HTMLElement>();
  const [focused, setFocused] = createSignal(!!props.alwaysFocused);
  const [promptSettings, setPromptSettings] = createStore<PromptSettings>({
    submitKeybinding: "enter",
  });
  const [highlights, setHighlights] = createSignal<HighlightId[]>([]);
  const addHighlight = (h: HighlightId) =>
    setHighlights((hs) => {
      if (hs.includes(h)) return hs;
      return [h, ...hs];
    });
  const removeHighlight = (h: HighlightId) =>
    setHighlights((hs) => hs.filter((hs) => hs !== h));

  const [promptPositioning, setPromptPositioning] = createSignal<{
    x: number;
    y: number;
  }>();

  const editorObj = {
    editor: () => null,
  } as { editor: Accessor<Editor | null> };

  createEffect(() => {
    const threadId = wire.services.threads.snapshot?.context?.threadId;
    if (threadId && allowNavigate()) {
      const projectId =
        wire.services.identity.snapshot.context.identity.workingContext
          .projectId;

      navigate(urls.thread(projectId, threadId));
    }
  });

  const submitPrompt: PromptContextValue["submitPrompt"] = async (
    customPrompt,
  ) => {
    const opts = {
      text: "",
      mentionedAssets: [] as string[],
    };

    const recipient = [Recipient.RecipientModel];

    if (customPrompt) {
      opts.text = customPrompt.text;
      opts.mentionedAssets = customPrompt.mentionedAssets;
    } else {
      const { text, mentionedAssets } = getPromptTextAndDecorations(
        editorObj.editor(),
      );
      opts.text = text || "";
      opts.mentionedAssets = mentionedAssets;
    }

    if (!opts.text) {
      logger.warn("trying to submit message but no text");
      return;
    }

    wire.services.limiting.guest.incrementInteractions({
      type: "prompt",
      prompt: opts.text,
    });

    if (!wire.services.limiting.guest.isInteractionAllowed()) return;

    if (props.disableSubmit) return;
    if (selectIsIdentityConnecting(wire.services.identity)) {
      logger.info(
        "trying to submit message but identity is not ready -- stopped",
        unwrap(wire.services.identity.snapshot.context.identity.workingContext),
      );
      return;
    }
    wire.services.websocket.connect();
    const payload = {};

    // onDone must be called regardless of the prompt we dispatch
    const onDone = () => {
      editorObj.editor()?.commands.clearContent();
      removeHighlight("send-button");
    };

    // A campaign prompt. We don't have any knowledge to add for a campaign prompt. Later, the user may take control
    // but at this stage we're simply introducing the user, and they can't have knowledge yet.
    if (props.transformationID) {
      logger.info("submitting a transformation prompt", {
        text: editorObj.editor()?.getText(),
        recipient,
        payload,
      });
      const prompt = newTransformationPrompt(opts.text, props.transformationID);
      await wire.services.threads.sendThreadPrompt({
        prompt,
      });
      return onDone();
    }

    logger.info("submitting a user-controlled prompt", {
      text: editorObj.editor()?.getText(),
      recipient,
      payload,
    });

    const inThread = wire.services.threads.snapshot.context.activeAssets;

    // Calculate any changes to our knowledge base
    const { addedAssets, removedAssets, useWorldKnowledge } = generateDiff({
      // Only add assets that are not part of the thread knowledge already
      added: opts.mentionedAssets.filter(
        (a) => !inThread?.find((t) => t.id === a),
      ),
      removed: [],
    });

    const prompt = newPromptWithDefaults(opts.text, "");

    // Update knowledge context if any assets have been mentioned
    if (addedAssets.length || removedAssets.length) {
      prompt.knowledge = {
        ...prompt.knowledge,
        world: useWorldKnowledge,
        assetContext: {
          added: addedAssets,
          removed: removedAssets,
        },
      };
    }

    await wire.services.threads.sendThreadPrompt({
      prompt,
    });

    wire.services.knowledge.resetQueuedKnowledgeChange();
    setAllowNavigate(true);

    stAnalytics.track("prompt_submitted", {
      prompt: opts.text,
      ...threadEventProps(),
    });

    return onDone();
  };

  const changeKnowledge = async () => {
    wire.services.limiting.guest.incrementInteractions({ type: "knowledge" });
    if (!wire.services.limiting.guest.isInteractionAllowed()) return;

    if (props.disableSubmit) return;
    if (selectIsIdentityConnecting(wire.services.identity)) {
      logger.info(
        "trying to submit knowledge change but identity is not ready -- stopped",
        unwrap(wire.services.identity.snapshot.context.identity.workingContext),
      );
      return;
    }

    // Prompt is responsible for managing the websocket connection. Given the user may not have submitted a prompt for
    // this current session, they may not have a websocket established. Let's try to connect.
    wire.services.websocket.connect();

    const { addedAssets, removedAssets, useWorldKnowledge } = generateDiff({
      added: [...wire.services.knowledge.queuedKnowledgeChange().added],
      removed: [...wire.services.knowledge.queuedKnowledgeChange().removed],
    });

    const knowledge: threads.Knowledge = {
      ...blankKnowledge,
      world: useWorldKnowledge,
      assetContext: {
        added: addedAssets,
        removed: removedAssets,
      },
    };

    // Refetch the list of files after an upload
    // TODO: Optimistic update instead of api call
    const [_, { refetch }] = wire.services.knowledge.assetsListResource;
    refetch();
    wire.services.knowledge.resetQueuedKnowledgeChange();
    await wire.services.threads.sendThreadKnowledge({
      knowledge,
    });

    setAllowNavigate(true);
  };

  const typeText = async (text: string, instant = false) => {
    if (instant) {
      editorObj.editor()?.commands.insertContent({ type: "text", text });
      return;
    }

    // Waiting a second before starting so that the user has some time
    // to notice the prompt
    await new Promise<void>((res) => setTimeout(res, 700));

    // Typing text will always take half a second
    const totalTimeInMiliseconds = 500;
    const tokens = Math.floor(text.length / 5);
    const timePerFrame = Math.floor(totalTimeInMiliseconds / tokens);

    for (let i = 0; i < text.length; i += 5) {
      await new Promise<void>((res) =>
        setTimeout(() => {
          editorObj.editor()?.commands.insertContent({
            type: "text",
            text: text.substring(i, i + 5),
          });
          res();
        }, timePerFrame),
      );
    }

    addHighlight("send-button");
  };

  onMount(() => {
    onCleanup(() => {
      props.onCleanup?.(editorObj.editor());

      const json = editorObj.editor()?.getJSON();
      if (!json) return;
      localStorage.setItem(LAST_PROMPT_KEY, JSON.stringify(json));
    });
  });

  return (
    <PromptContext.Provider
      value={{
        editor: () => editorObj.editor(),
        submitPrompt,
        changeKnowledge,
        focused,
        setFocused: (args) => {
          if (props.alwaysFocused) return setFocused(true);
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          return setFocused(args as any);
        },
        promptRef,
        setPromptRef,
        isConnecting,
        promptSettings,
        setPromptSettings,
        highlights,
        removeHighlight,
        addHighlight,
        uploader,
        rawProps: props,
        promptPositioning,
        setPromptPositioning,
        typeText,
        showUploadModal,
        setShowUploadModal,
      }}
    >
      {/* A hacky-ish way to create the editor inside the prompt context provider, so that the editor has access
      to usePromptContext inside itself */}
      {(() => {
        editorObj.editor = createEditor({
          extensions: getEditorExtensions(),
          editorProps: {
            attributes: {
              class:
                "outline-none max-h-[224px] sm:max-h-[400px] overflow-y-auto break-words",
              "data-testid": "prompt-text-input",
            },
            transformPastedText(text) {
              return transformPastedText(text);
            },
            transformPastedHTML(html) {
              return transformPastedHTML(html);
            },
          },
          onCreate({ editor }) {
            if (props.autoFocusInput) {
              editor.commands.focus();
            }
            if (props.initialPrompt) {
              if (typeof props.initialPrompt === "string") {
                // do not await
                typeText(props.initialPrompt);
              } else {
                editor.commands.setContent(props.initialPrompt);
              }
            } else {
              const prompt = localStorage.getItem(LAST_PROMPT_KEY);
              if (prompt) {
                try {
                  const parsed = JSON.parse(prompt) as JSONContent;
                  // This is here to stop the front-page madlib prompt from being loaded and shown in the threads pages
                  // There should be a better way to handle this once we start using variables in other places too
                  if (
                    parsed.content?.some(
                      (c) =>
                        c.type === "paragraph" &&
                        c.content?.some((c) => c.type === "variable"),
                    )
                  )
                    return;
                  editor.commands.setContent(parsed);
                  localStorage.removeItem(LAST_PROMPT_KEY);
                } catch (error) {}
              }
            }
          },
        });
        return null;
      })()}
      {props.children}

      <ChatFileUpload
        changeKnowledge={changeKnowledge}
        open={showUploadModal()}
        setOpen={setShowUploadModal}
      />
    </PromptContext.Provider>
  );
};

import {
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createMemo,
  onCleanup,
  onMount,
} from "solid-js";
import { EditorContent } from "tiptap-solid";
import { EditorFocusedAnimation } from "./components/EditorFocusedAnimation";
import { useClickOutside } from "~/lib/useClickOutside";
import { ChatTopBar } from "./components/ChatTopBar";
import { ChatBottomBar } from "./components/ChatBottomBar";
import { ChatSendButton } from "./components/ChatSendButton";
import { usePromptContext } from "./PromptContext";
import { ChatTips } from "./ChatTips";
import { ChatMicrophone } from "./components/ChatMicrophone";
import { ChatBackdrop } from "./components/ChatBackdrop";
import { createElementSize } from "@solid-primitives/resize-observer";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { Motion } from "solid-motionone";
import { spring } from "motion";
import { CircularStatus } from "~/components/CircularStatus";
import { twMerge } from "tailwind-merge";
import type { Editor, JSONContent } from "@tiptap/core";
import { EnterpriseGradePromptLink } from "~/components/EnterpriseGradePromptLink";

export type PromptProps = {
  disableSubmit?: boolean;
  positioning?: string;
  alwaysFocused?: boolean;
  hideToolbars?: boolean;
  autoFocusInput?: boolean;
  hideBackdrop?: boolean;
  initialPrompt?: string | JSONContent;
  transformationID?: string;
  onCleanup?: (editor: Editor | null) => void;
};

export const PersistentPrompt = (props: {
  class?: string;
  showEnterpriseGrade?: boolean;
}) => {
  const {
    editor,
    focused,
    setFocused,
    promptRef,
    setPromptRef,
    rawProps,
    promptPositioning,
  } = usePromptContext();
  const textBoxFocused = createMemo(() => !!editor()?.isFocused);
  const empty = createMemo(() => !!editor()?.isEmpty);
  createEffect(() => {
    if (textBoxFocused()) setFocused(true);
  });

  const { setRef } = useClickOutside(() => {
    if (focused()) setFocused(false);
  }, true);

  const onFocusChange = (event: FocusEvent) => {
    if (
      event.target instanceof Element &&
      promptRef()?.contains(event.target)
    ) {
      if (!focused()) setFocused(true);
    } else {
      if (focused()) setFocused(false);
    }
  };

  onMount(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === "k" &&
        (event.metaKey || event.ctrlKey) &&
        !textBoxFocused()
      ) {
        editor()?.commands.focus();
      }

      if (event.key === "Escape" && focused()) {
        editor()?.commands.blur();
        setFocused(false);
      }
    };
    document.body.addEventListener("focus", onFocusChange, { capture: true });
    window.addEventListener("keydown", onKeyDown, { capture: true });
    onCleanup(() => {
      document.body.removeEventListener("focus", onFocusChange, {
        capture: true,
      });
      window.removeEventListener("keydown", onKeyDown, { capture: true });
    });
  });

  const size = createElementSize(promptRef);

  return (
    <div
      class={twMerge("flex justify-center p-4 z-10", props.class)}
      classList={{
        [rawProps.positioning || ""]: !!rawProps.positioning,
        "fixed bottom-0 left-0 right-0": !rawProps.positioning,
        "pb-4 sm:pb-10": !!props.showEnterpriseGrade,
      }}
      data-testid="prompt-container"
    >
      <Show when={!rawProps.hideBackdrop}>
        <ChatBackdrop height={size.height || 60} />
      </Show>
      <Motion.div
        initial={{
          x: promptPositioning()?.x || 0,
          y: promptPositioning()?.y || 0,
        }}
        animate={{
          x: promptPositioning()?.x || 0,
          y: promptPositioning()?.y || 0,
        }}
        transition={{
          y: {
            easing: spring({ stiffness: 100, damping: 15, mass: 1 }),
            // easing: "ease-out",
            // duration: 0.6,
          },
        }}
        class="w-full max-w-thread px-0 md:px-6 pb-3"
      >
        <div
          ref={(el) => {
            setPromptRef(el);
            setRef(el);
          }}
          class="relative rounded bg-white dark:bg-slate-950 border dark:border-white/10 shadow-xl"
        >
          {/* <Files /> */}

          <Show when={!rawProps.hideToolbars}>
            <ChatTopBar />
          </Show>

          <div class="flex items-center w-full">
            <ChatMicrophone />

            <EditorContent
              class="text-base font-light flex-auto cursor-text p-4 mr-2 px-0 pr-0 dark:text-white"
              onClick={(e) => {
                if (e.currentTarget === e.target) {
                  editor()?.commands.focus("all");
                }
              }}
              editor={editor()}
            />

            <ChatTips />

            <ChatSendButton />
          </div>

          <Show when={!rawProps.hideToolbars}>
            <ChatBottomBar />
          </Show>

          <EditorFocusedAnimation focused={focused()} empty={empty()} />

          <Show when={props.showEnterpriseGrade}>
            <div class="hidden sm:block absolute -bottom-8 sm:-bottom-10 left-0 right-0 text-slate-600 text-sm">
              <EnterpriseGradePromptLink iconColor="text-indigo-600" />
            </div>
          </Show>
        </div>
      </Motion.div>
    </div>
  );
};

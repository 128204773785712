import type { q } from "@repo/client";
import type { Editor } from "@tiptap/core";
import type { ChatVariable } from "./extensions/Variables/Variables.types";

type StTextNode = {
  type: "text";
  text: string;
};
type StTagNode = {
  type: "tagNode";
  attrs: q.ControlplaneSsAsset;
};

type StVariable = {
  type: "variable";
  attrs: ChatVariable;
};

type StInlineNode = StTextNode | StTagNode | StVariable;
type StParagrahNode = {
  type: "paragraph";
  content?: StInlineNode[];
};
type StListItemNode = {
  type: "listItem";
  content?: StBlockNode[];
};
type StBulletListNode = {
  type: "bulletList";
  content?: StListItemNode[];
};
type StOrderedListNode = {
  type: "orderedList";
  attrs: {
    start: number;
  };
  content?: StListItemNode[];
};

type StBlockNode = StParagrahNode | StOrderedListNode | StBulletListNode;

type StDoc = {
  type: "doc";
  content: StBlockNode[];
};

/**
 * Converts the tiptap editor json structure to a mardown string
 * And also returns any extra decorations in the prompt (e.g. mentioned files)
 */
export const getPromptTextAndDecorations = (editor: Editor | null) => {
  if (!editor) return { text: undefined, mentionedAssets: [] };

  const json = editor.getJSON() as StDoc;
  const newline = (str: string) => `${str}\n`;
  const nest = (str: string, nesting: number) =>
    `${"  ".repeat(nesting)}${str}`;

  const mentionedAssets: string[] = [];

  const parseInlineNode = (node: StInlineNode, nesting: number) => {
    switch (node.type) {
      case "text":
        return node.text;
      case "tagNode":
        mentionedAssets.push(node.attrs.id);
        return `"${node.attrs.displayName}"`;
      case "variable":
        if (node.attrs.type === "predefined") {
          return node.attrs.value;
        }
        break;
      default:
        break;
    }
  };
  const parseInlineNodes = (nodes: StInlineNode[], nesting: number) =>
    nodes.map((n) => parseInlineNode(n, nesting)).join("");

  const parseListItems = (
    nodes: StListItemNode[],
    nesting: number,
    marker: string | number,
  ) =>
    nodes
      .map((n, index) =>
        parseListItem(
          n,
          nesting,
          typeof marker === "string" ? marker : `${index + marker}.`,
        ),
      )
      .join("\n");

  const parseListItem = (
    node: StListItemNode,
    nesting: number,
    marker: string | number,
  ): string =>
    nest(`${marker} ${parseBlockNodes(node.content ?? [], nesting)}`, nesting);

  const parseBlockNode = (node: StBlockNode, nesting: number) => {
    switch (node.type) {
      case "paragraph":
        return newline(parseInlineNodes(node.content ?? [], nesting));
      case "bulletList":
        return parseListItems(node.content ?? [], nesting + 1, "*");
      case "orderedList":
        return parseListItems(
          node.content ?? [],
          nesting + 1,
          node.attrs.start,
        );
      default:
        return "";
    }
  };
  const parseBlockNodes = (nodes: StBlockNode[], nesting: number) =>
    nodes.map((n) => parseBlockNode(n, nesting)).join("\n");

  const text = parseBlockNodes(json.content, -1).trim();

  navigator.clipboard.writeText(text);

  return {
    text,
    mentionedAssets,
  };
};

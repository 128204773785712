import { twJoin } from "tailwind-merge";
import styles from "./ChatBackdrop.module.css";
import type { Component } from "solid-js";
import { useWire } from "~/wire";

export const ChatBackdrop: Component<{ height: number }> = (props) => {
  const wire = useWire();
  return (
    <div
      aria-hidden
      class="fixed bottom-0 right-0 pointer-events-none -z-10"
      style={{
        left: `${wire.services.appLayout.data.leftSidebar.size}px`,
        right: `${wire.services.appLayout.data.rightSidebar.size}px`,
      }}
    >
      <div
        class={twJoin("w-full mx-auto", styles["gradient-blur"])}
        style={{ height: `${props.height + 80}px` }}
      />
    </div>
  );
};

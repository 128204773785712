import type { stid } from "@repo/client";
import type { NamedLogger } from "@repo/logger";
import type {
  AssetCreatedEvent,
  AssetCreationFailedEvent,
  UpdateProgressEvent,
  UserConfirmEvent,
  abortUploadEvent,
  notifyAPIEvent,
  uploadCompleteEvent,
  uploadFailedEvent,
} from "./assetUploadMachine";

/**
 * assetUploadEventFactory creates events for the asset upload machine.
 * @param loggerDep
 */
export const assetUploadEventFactory = (loggerDep: NamedLogger) => {
  const logger = loggerDep.child("assetUploadEventFactory");
  return {
    newUpdateProgressEvent: (progress: number): UpdateProgressEvent => {
      const type = "assetUploadMachine.updateProgress";
      if (_LOG) logger.info(type, { progress });
      return { type, progress };
    },
    newUserConfirmEvent: (): UserConfirmEvent => {
      const type = "assetUploadMachine.createAsset";
      if (_LOG) logger.info(type);
      return { type };
    },
    newAssetCreatedEvent: (
      assetID: stid.AssetStringID,
      signedURL: string,
    ): AssetCreatedEvent => {
      const type = "assetUploadMachine.assetCreated";
      if (_LOG) logger.info(type, { assetID, signedURL });
      return { type, assetID, signedURL };
    },
    newAssetCreationFailedEvent: (
      message: string,
    ): AssetCreationFailedEvent => {
      const type = "assetUploadMachine.assetCreationFailed";
      if (_LOG) logger.info(type);
      return { type, errorMessage: message };
    },
    newUploadCompleteEvent: (): uploadCompleteEvent => {
      const type = "assetUploadMachine.uploadComplete";
      if (_LOG) logger.info(type);
      return { type };
    },
    newUploadFailedEvent: (message: string): uploadFailedEvent => {
      const type = "assetUploadMachine.uploadFailed";
      if (_LOG) logger.info(type);
      return { type, errorMessage: message };
    },
    newNotifyAPIEvent: (): notifyAPIEvent => {
      const type = "assetUploadMachine.notifyAPI";
      if (_LOG) logger.info(type);
      return { type };
    },
    newAbortUploadEvent: (): abortUploadEvent => {
      const type = "assetUploadMachine.abortUpload";
      if (_LOG) logger.info(type);
      return { type, errorMessage: "Upload aborted" };
    },
  };
};

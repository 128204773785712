import { type ParentComponent, Show, type Component } from "solid-js";
import { Presence, Motion } from "solid-motionone";
import type { StIconComponent } from "~/components/icons";
import { usePromptContext } from "../PromptContext";
import { Tooltip, TooltipTrigger } from "~/components/popups/Tooltip";
import { StButton } from "~/components/buttons";
import { TbPaperclip, TbSlash, TbTag } from "solid-icons/tb";
import { selectIsIdentityConnecting } from "~/domains/identity/hooks";
import { useWire } from "~/wire";
export const ChatBottomBar: Component = () => {
  const { focused, editor, setShowUploadModal } = usePromptContext();
  const wire = useWire();

  return (
    <Presence exitBeforeEnter>
      <Show when={focused()}>
        <Motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: "36px",
          }}
          exit={{ opacity: 0, height: 0 }}
          class="relative overflow-y-hidden"
        >
          <div class="flex items-center gap-2 absolute bottom-0 left-0 right-0 text-2xs text-gray-600 dark:text-gray-300 rounded-b px-2 py-2">
            <ToolbarButton
              label="Slash command"
              onClick={() => editor()?.commands.invokeSlashCommand()}
              icon={TbSlash}
            />
            <ToolbarButton
              label="Upload files"
              disabled={selectIsIdentityConnecting(wire.services.identity)}
              onClick={() => setShowUploadModal(true)}
              icon={TbPaperclip}
            />
            <ToolbarButton
              label="Reference a tag or file"
              onClick={() => editor()?.commands.invokeTag()}
              icon={TbTag}
            />
            {/* <ToolbarButton
              label="Mention someone"
              onClick={() => editor()?.commands.invokeMention()}
              icon={TbAt}
            /> */}

            {/* <UpcomingFeature
              label="Prompt Format Text Button"
              mount={promptRef()}
            >
              <ToolbarButton label="Format Text" icon={TbTypography} />
            </UpcomingFeature> */}
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};

const ToolbarButton: ParentComponent<{
  label: string;
  onClick?: () => void;
  icon: StIconComponent;
  disabled?: boolean;
}> = (props) => {
  return (
    <Tooltip theme="invert" placement="top" content={<p>{props.label}</p>}>
      <TooltipTrigger as="span">
        <StButton
          size="sm"
          onClick={props.onClick}
          icon={props.icon}
          disabled={props.disabled}
          label={props.label}
        />
      </TooltipTrigger>
    </Tooltip>
  );
};

import { For, type Component } from "solid-js";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { HoverCard } from "@kobalte/core";
import { usePromptContext } from "../PromptContext";
import { UpcomingFeature } from "~/components/UpcomingFeature";
export const QuestionLibraryQuestions = [
  {
    label: "Quick summary",
    createdBy: "Storytell",
    text: 'Generate a concise summary of the given document(s) that includes a 2-sentence "Overview" section and a "Key Concepts" section with up to 5 bullet points that give detail on the most important topics.',
  },
  {
    label: "Meeting Summary + AIs",
    createdBy: "Storytell",
    text: "Write a comprehensive summary of this content, including all the topics covered. start with a title for the content, including the names of who was involved. list the topics in bullet point format and then write a paragraph on each topic. include sentiment, questions asked, and any action items. An action item is a documented, discrete, and specific task, event, unit, or activity that can be assigned to and completed by a particular person, who is known as the owner of the action item. Action items also have due dates or deadlines. At the end of the content, include the action items in bullet point format, along with their deadline and owner. If there are no action items, say “No action items”",
  },
  {
    label: "SWOT analysis",
    createdBy: "Storytell",
    text: `I am on the leadership team of [COMPANY] and need to understand how to make [COMPANY] as successful as possible. 

Please perform a SWOT analysis of the content in table format. Ensure that each "Strength" and "Weakness" is internal, and each "Opportunity" and "Threat" is external. Provide at least 10 examples of each.

Below the SWOT analysis, create an additional set of tables. 

The first table should be called "Double Down". This table contains a set of insights that are produced by combining internal "Strengths" with external "Opportunities". In Column 1, choose one of the "Strengths" from the SWOT analysis, and one of the "Opportunities" from the SWOT analysis. Combine them to create a specific set of insights on how to leverage the strength and opportunity.

The second table should be called "Fixable Threats". This table contains a set of insights that are produced by combining internal "Strengths" with external "Threats". In Column 1, choose one of the "Strengths" from the SWOT analysis, and one of the "Threats" from the SWOT analysis. Combine them to create a specific set of insights on how to use the strength to deal with the threats.

The third table should be called "Inaccessible Opportunity". This table contains a set of insights that are produced by combining internal "Weaknesses" with external "Opportunities". In Column 1, choose one of the "Weaknesses" from the SWOT analysis, and one of the "Opportunities" from the SWOT analysis. Combine them to create a specific set of insights on why the opportunity is inaccessible due to the internal weakness.

The fourth table should be called "Existential Threats". This table contains a set of insights that are produced by combining internal "Weakness" with external "Threats". In Column 1, choose one of the "Weaknesses" from the SWOT analysis, and one of the "Threats" from the SWOT analysis. Combine them to create a specific set of insights on why the weakness may cause an existential threat that could severely damage the company.`,
  },
  {
    label: "What should I ask?",
    createdBy: "Storytell",
    text: "List the top five questions I should ask of this content",
  },
  {
    label: "Explain it like I'm five (ELI5)",
    createdBy: "Storytell",
    text: `You are tasked with explaining a concept in a simple, easy-to-understand manner, as if you were explaining it to fifth grader, which is the level that the newspaper USA Today is written in. Your goal is to make complex ideas accessible without losing the essence of the concept. Follow these guidelines to create your explanation: 1. Simplify the concept: Break down complex ideas into their most basic components. Avoid jargon or technical terms unless absolutely necessary. 2. Use concrete examples: Relate the concept to everyday objects, experiences, or situations that a fifth grader might be familiar with. Fifth graders are around 10-12 years old. 3. Use analogies: Compare the concept to something a fifth grader already understands. Keep in mind that fifth graders have rich internal lives, emotionally and intellectually. So don’t see your reader short! 4. Keep it brief: Limit your explanation to a few short paragraphs. Humans have shorter attention spans these days thanks to social media: so concise explanations are more effective. 5. Use language that matches your audience: Adjust your vocabulary and sentence structure to match the target age group. 6. Engage the imagination: Use descriptive language that helps a fifth grader visualize the concept. 7. Avoid oversimplification: While simplifying, ensure that the core idea remains accurate and isn't misrepresented. Please provide your explanation within <explanation> tags. Begin with a brief introduction that captures the reader’s attention, then proceed with your simplified explanation of the concept. Use the uploaded content as the concept to explore unless you get different instructions from the user.`,
  },
];

export const ChatQuestionLibrary: Component = () => {
  const { editor, promptRef } = usePromptContext();
  return (
    <div class="max-w-full overflow-x-auto">
      <div class="grid grid-cols-3 gap-3 pt-2 pb-4 px-4 text-sm text-black dark:text-white w-full min-w-[700px]">
        <For each={QuestionLibraryQuestions}>
          {(item) => (
            <Question
              {...item}
              onClick={() => {
                editor()?.commands.setContent({
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: item.text,
                    },
                  ],
                });
                editor()?.commands.focus();
              }}
            />
          )}
        </For>
        <div class="w-full flex items-center gap-3">
          <UpcomingFeature
            mount={promptRef()}
            label="Question Library View all"
            triggerProps={{ class: "w-1/2 flex-shrink-0" }}
          >
            <button
              type="button"
              class="w-full flex items-center justify-center gap-3 bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-gray-300 dark:border-gray-600 rounded px-3 py-2"
            >
              <Icon
                name={IconName.Squares}
                size={IconsSize.Mini}
                class="-ml-4"
              />
              View all
            </button>
          </UpcomingFeature>
          <UpcomingFeature
            mount={promptRef()}
            label="Question Library New"
            triggerProps={{ class: "w-1/2 flex-shrink-0" }}
          >
            <button
              type="button"
              class="w-full flex items-center justify-center gap-3 bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-gray-300 dark:border-gray-600 rounded px-3 py-2"
            >
              <Icon name={IconName.Plus} size={IconsSize.Mini} class="-ml-4" />
              New
            </button>
          </UpcomingFeature>
        </div>
      </div>
    </div>
  );
};

const Question: Component<{
  label: string;
  createdBy: string;
  text: string;
  onClick: () => void;
}> = (props) => {
  const { promptRef } = usePromptContext();
  return (
    <HoverCard.Root placement="top" openDelay={250} closeDelay={250}>
      <HoverCard.Trigger
        as="button"
        onClick={props.onClick}
        class="w-full flex items-center justify-between bg-gray-200 dark:bg-slate-800 hover:bg-gray-300 dark:hover:bg-slate-700 border border-gray-300 dark:border-gray-600 rounded px-3 py-2"
      >
        {props.label}
        <Icon name={IconName.PaperAirplane} size={IconsSize.Mini} />
      </HoverCard.Trigger>
      <HoverCard.Portal mount={promptRef()}>
        <HoverCard.Content class="flex flex-col gap-2 max-w-96 bg-white dark:bg-slate-900 text-gray-800 dark:text-slate-100 rounded border dark:border-slate-600 shadow-lg p-4 animate-fade-out kb-expanded:animate-fade-in">
          <HoverCard.Arrow />
          <h3 class="text-base">{props.label}</h3>
          <div>
            <span class="text-xs px-2 py-1 rounded bg-violet-100 text-violet-800 dark:bg-violet-800 dark:text-violet-100">
              Created by {props.createdBy}
            </span>
          </div>

          <p class="text-sm">How we’re asking this question</p>

          <p class="p-2 bg-gray-100 dark:bg-slate-800 rounded max-h-48 overflow-y-auto text-sm max-w-full">
            {props.text}
          </p>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

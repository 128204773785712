export enum IconsSize {
  Default = 0,
  Micro = 1,
  Mini = 2,
}

export type InternalIconProps = {
  size?: IconsSize;
};

export type IconKey = string;

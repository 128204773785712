import { Icon, IconName, IconsSize } from "~/components/icons";
import { usePromptContext } from "../PromptContext";
import { Show, createMemo, onMount, createSignal, onCleanup } from "solid-js";
import { Motion } from "solid-motionone";
import { useHighlightElementOnScreen } from "~/lib/useHighlightElementOnScreen";
import { CircularStatus } from "~/components/CircularStatus";
import { useWire } from "~/wire";

export const ChatSendButton = () => {
  const { submitPrompt, editor, highlights, isConnecting, removeHighlight } =
    usePromptContext();
  const wire = useWire();

  const [ref, setRef] = createSignal<HTMLElement>();

  const isHighlighted = createMemo(
    () =>
      highlights().includes("send-button") &&
      wire.services.limiting.guest.isInteractionAllowed(),
  );

  useHighlightElementOnScreen({
    ref: ref,
    enabled: isHighlighted,
    trackElementPosition: true,
    onClear: () => removeHighlight("send-button"),
    config: {
      stagePadding: 25,
    },
    highlightOpts: {
      popover: {
        description: "Tip: Hit “Send” to get started",
      },
    },
  });
  return (
    <button
      ref={setRef}
      type="button"
      onClick={() => submitPrompt()}
      disabled={editor()?.isEmpty}
      class="mr-2 ml-2 mb-[14px] text-textOff self-end transition duration-300 ease-in-out font-sans select-none relative group/button justify-center text-center items-center rounded-full whitespace-nowrap inline-flex text-sm aspect-square h-8"
    >
      <div class="flex items-center leading-none justify-center gap-xs">
        <Show when={isHighlighted()}>
          <Motion.span
            class="absolute"
            animate={{
              scale: [1, 5, 1],
              opacity: [1, 0, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Number.POSITIVE_INFINITY,
              easing: ["ease-out", "ease-out", "linear"],
              delay: 0.75,
              scale: {
                offset: [0, 0.6, 1],
              },
              opacity: {
                offset: [0, 0.6, 1],
              },
            }}
          >
            <Show when={!isConnecting()}>
              <Icon
                name={IconName.PaperAirplane}
                size={IconsSize.Default}
                class="text-violet-500 dark:text-white"
              />
            </Show>
          </Motion.span>
        </Show>

        <Motion.span
          class="relative"
          animate={{
            scale: isHighlighted() ? [1, 0.8, 1.5, 1] : 1,
          }}
          transition={{
            duration: 1.5,
            repeat: Number.POSITIVE_INFINITY,
            scale: {
              offset: [0, 0.8],
            },
          }}
        >
          <Show fallback={<ConnectingIndicator />} when={!isConnecting()}>
            <Icon
              name={IconName.PaperAirplane}
              size={IconsSize.Default}
              class="text-violet-500 dark:text-white"
            />
          </Show>
        </Motion.span>
      </div>
    </button>
  );
};

const ConnectingIndicator = () => {
  const [progress, setProgress] = createSignal(0);
  onMount(() => {
    const interval = setInterval(() => {
      const next = progress() + 10;
      setProgress(next > 100 ? 0 : next);
    }, 50);
    onCleanup(() => clearInterval(interval));
  });

  return <CircularStatus progress={progress()} title={"Connecting..."} />;
};

const htmlEntities = {
  "&nbsp;": " ",
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#39;": "'",
  "&apos;": "'",
  "&cent;": "¢",
  "&pound;": "£",
  "&yen;": "¥",
  "&euro;": "€",
  "&copy;": "©",
  "&reg;": "®",
  "&trade;": "™",
};

const specialCharacters = {
  "\u00A0": " ", // Non-breaking space
  "\u2018": "'", // Left single quotation mark
  "\u2019": "'", // Right single quotation mark
  "\u201C": '"', // Left double quotation mark
  "\u201D": '"', // Right double quotation mark
  "\u2013": "-", // En dash
  "\u2014": "--", // Em dash
  "\u2026": "...", // Horizontal ellipsis
};

export const transformPastedText = (text: string): string => {
  let transformedText = text;

  // Replace special Unicode characters
  for (const [char, replacement] of Object.entries(specialCharacters)) {
    transformedText = transformedText.replace(
      new RegExp(char, "g"),
      replacement,
    );
  }

  return transformedText;
};

export const transformPastedHTML = (html: string): string => {
  let transformedHTML = html;

  // Replace HTML entities
  for (const [entity, replacement] of Object.entries(htmlEntities)) {
    transformedHTML = transformedHTML.replace(
      new RegExp(entity, "g"),
      replacement,
    );
  }

  return transformPastedText(transformedHTML);
};

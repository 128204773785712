import { Popover } from "@kobalte/core";
import type { ParentComponent } from "solid-js";
import { useWire } from "~/wire";
import { StButton } from "~/components/buttons";
import { TbFilePencil } from "solid-icons/tb";

export const UpcomingFeature: ParentComponent<{
  label: string;
  mount?: HTMLElement;
  triggerProps?: Popover.PopoverTriggerProps;
  text?: string;
}> = (props) => {
  const wire = useWire();

  const subject = () => `${props.label}`;
  const onFeedbackClick = () => {
    wire.services.feedback.openFeedbackPanel(subject());
  };

  return (
    <Popover.Root>
      <Popover.Trigger
        as="div"
        {...props.triggerProps}
        onClick={(e) => e.stopImmediatePropagation()}
      >
        {props.children}
      </Popover.Trigger>

      <Popover.Portal mount={props.mount}>
        <Popover.Content class="bg-indigo-900 text-white rounded-lg p-5 animate-fade-out kb-expanded:animate-fade-in z-50">
          <Popover.Arrow />

          <div>
            <Popover.Title class="text-indigo-200 text-2xs uppercase tracking-wider mb-3">
              Feature coming
            </Popover.Title>
            <div> {props.text || "How would you like it to work?"}</div>

            <div class="mt-2">
              <StButton
                onClick={onFeedbackClick}
                label="Share your feedback"
                icon={TbFilePencil}
              >
                Share your feedback
              </StButton>
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

import type { ParentComponent } from "solid-js";
import { XMLNS } from "~/components/icons/consts";
import { sizeToClass, sizeToViewBox } from "~/components/icons/size";
import { IconsSize, type InternalIconProps } from "~/components/icons/types";

export type IconSVGProps = {
  strokeWidth?: string;
  stroke?: string;
  fill?: string;
  size?: IconsSize;
  title?: string;
};

export const IconSVG: ParentComponent<IconSVGProps> = (props) => (
  <svg
    xmlns={XMLNS}
    viewBox={sizeToViewBox(props.size)}
    stroke-width={props.strokeWidth}
    stroke={props.stroke}
    fill={props.fill}
    class={sizeToClass(props.size)}
  >
    <title>{props.title ?? "Icon"}</title>
    {props.children}
  </svg>
);

export const isSize = (props: InternalIconProps) => (s: IconsSize) =>
  s === (props.size || IconsSize.Default);

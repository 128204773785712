import { Dialog } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import { type Component, type Setter, createEffect, on } from "solid-js";
import { Icon, IconName } from "~/components/icons";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { FileDropzone } from "~/domains/marketing/components/FileDropzone";

export const ChatFileUpload: Component<{
  open: boolean;
  setOpen: Setter<boolean>;
  changeKnowledge: () => Promise<void>;
}> = (props) => {
  const { threadEventProps } = useThreadEventProperties();
  const onUploaded = () => {
    props.changeKnowledge();
    props.setOpen(false);
  };

  createEffect(
    on(
      () => props.open,
      (open) => {
        if (open) {
          stAnalytics.track("file_upload_modal_opened", threadEventProps());
        }
      },
    ),
  );

  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay class="fixed z-50 inset-0 bg-indigo-1100/50" />
        <div class="fixed inset-0 z-50 flex items-center justify-center">
          <Dialog.Content class="bg-indigo-800 text-white rounded-lg p-5 animate-fade-out kb-expanded:animate-fade-in min-w-[80vw]">
            <div class="flex items-center justify-between gap-5">
              <Dialog.Title class="font-bold">Upload files</Dialog.Title>
              <Dialog.CloseButton class="dialog__close-button">
                <Icon name={IconName.Xmark} />
              </Dialog.CloseButton>
            </div>

            <div class="mt-5">
              <FileDropzone close={onUploaded} context="thread" />
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { createEffect, runWithOwner, type Component } from "solid-js";
import type { TagsSuggestionProps } from "./Tags.extension";
import { getTiptapSolidReactiveOwner, NodeViewWrapper } from "tiptap-solid";
import type { NodeViewProps } from "@tiptap/core";
import { HoverCard } from "@kobalte/core";
import { ChatPopupMenu } from "../ChatPopupMenu";
import { Icon, IconName, IconsSize } from "~/components/icons";
import { stAnalytics } from "@repo/analytics";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { useWire } from "~/wire";

export type TagsProps = TagsSuggestionProps;

export const TagsContainer: Component<TagsProps> = (props) => {
  return (
    <ChatPopupMenu
      {...props}
      onSelect={(item: TagsProps["items"][number]) => {
        const owner = getTiptapSolidReactiveOwner(props.editor);
        const wire = runWithOwner(owner, useWire);
        if (!wire) return;

        stAnalytics.track("prompt_tag_referenced", {
          tagType: "asset",
          tagId: item?.id ?? "unknown",
          ...getThreadEventProperties({
            workingContext:
              wire.services.identity.snapshot.context.identity.workingContext,
            threadId: wire.services.threads.snapshot.context.threadId,
            threadMessages: wire.services.threads.messages(),
          }),
        });
      }}
    >
      {(item) => (
        <span class="flex items-center gap-4">
          <Icon name={IconName.Tag} size={IconsSize.Mini} />
          <span>{item.displayName}</span>
        </span>
      )}
    </ChatPopupMenu>
  );
};

export const TagNode: Component<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper as="span">
      <span class="rounded px-1 bg-green-100 text-green-800 dark:bg-green-700 dark:text-green-100">
        #{props.node.attrs.displayName}
      </span>
    </NodeViewWrapper>
  );
};

import type { ChainedCommands, Editor } from "@tiptap/core";
import { runWithOwner } from "solid-js";
import { getTiptapSolidReactiveOwner } from "tiptap-solid";
import { usePromptContext } from "../PromptContext";

export const insertCharacterCommand =
  (char: string) =>
  ({ editor, chain }: { editor: Editor; chain: () => ChainedCommands }) => {
    // Get the current selection
    const { $from } = editor.state.selection;

    // Check if there is a space character before the current position
    const hasSpaceBefore =
      $from.nodeBefore?.text?.slice(-1) === " " || !$from.nodeBefore;

    // Define the content to insert
    const contentToInsert = hasSpaceBefore ? char : ` ${char}`;

    // Insert the content at the current position
    chain().insertContent(contentToInsert).focus();
    return true;
  };

export const getPromptContextFromEditor = (editor: Editor) => {
  const owner = getTiptapSolidReactiveOwner(editor);
  const context = runWithOwner(owner, usePromptContext);
  return context;
};

import type { SuggestionProps } from "@tiptap/suggestion";
import {
  For,
  type JSXElement,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";
import { Motion } from "solid-motionone";
import { VirtualElementPopup } from "~/components/popups/VirtualElementPopup";
import { slashPopupAnimationProps } from "./SlashCommand/helpers";
import { usePromptContext } from "../PromptContext";

const clamp = (n: number, min: number, max: number) =>
  Math.max(min, Math.min(n, max));

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function ChatPopupMenu<T extends SuggestionProps<any>>(
  props: T & {
    children: (
      item: T["items"][number],
      index: number,
      focused: boolean,
    ) => JSXElement;
    onSelect?: (item: T["items"][number]) => void;
  },
) {
  const { promptRef } = usePromptContext();
  const onClose = () => {
    props.editor.commands.keyboardShortcut("Escape");
  };
  const [focused, setFocused] = createSignal(0);

  const onSelect = (item: T["items"][number]) => {
    props.command(item);
    props.onSelect?.(item);
  };

  onMount(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        onSelect(props.items[focused()]!);
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.key === "ArrowDown") {
        setFocused(clamp(focused() + 1, 0, props.items.length - 1));
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
      if (e.key === "ArrowUp") {
        setFocused(clamp(focused() - 1, 0, props.items.length - 1));
        e.preventDefault();
        e.stopImmediatePropagation();
        return;
      }
    };
    window.addEventListener("keydown", listener, { capture: true });
    onCleanup(() => {
      window.removeEventListener("keydown", listener, { capture: true });
    });
  });

  return (
    <VirtualElementPopup
      getBoundingClientRect={() => props.clientRect?.()}
      positionUpdateTrigger={() => props.decorationNode}
      mount={promptRef() as HTMLElement}
      onClose={onClose}
    >
      <Motion.div
        {...slashPopupAnimationProps}
        class="bg-white dark:bg-slate-700 dark:text-white rounded border dark:border-slate-500 shadow-lg"
      >
        <div class="flex-col gap-2 max-h-96 overflow-y-auto">
          <For each={props.items}>
            {(item, index) => (
              <button
                type="button"
                class="w-full group outline-none rounded pl-4 py-4 pr-2 text-sm flex items-center"
                classList={{
                  "bg-gray-100 dark:bg-slate-600": index() === focused(),
                  "bg-white dark:bg-slate-700": index() !== focused(),
                }}
                onClick={() => onSelect(item)}
              >
                {props.children(item, index(), focused() === index())}
              </button>
            )}
          </For>
        </div>
      </Motion.div>
    </VirtualElementPopup>
  );
}

import type { stid } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import { assign, setup } from "xstate";
import type { UploadAsset } from "./types";

// EVENTS --------------------------------------------------------------------------------------------------------------

export type UpdateProgressEvent = {
  type: "assetUploadMachine.updateProgress";
  progress: number;
};

export type UserConfirmEvent = { type: "assetUploadMachine.createAsset" };
export type AssetCreatedEvent = {
  type: "assetUploadMachine.assetCreated";
  assetID: stid.AssetStringID;
  signedURL: string;
};
export type AssetCreationFailedEvent = {
  type: "assetUploadMachine.assetCreationFailed";
  errorMessage: string;
};
export type uploadCompleteEvent = { type: "assetUploadMachine.uploadComplete" };
export type uploadFailedEvent = {
  type: "assetUploadMachine.uploadFailed";
  errorMessage: string;
};
export type notifyAPIEvent = { type: "assetUploadMachine.notifyAPI" };
export type abortUploadEvent = {
  type: "assetUploadMachine.abortUpload";
  errorMessage: string;
};
export type UploadAssetEvents =
  | UpdateProgressEvent
  | UserConfirmEvent
  | AssetCreatedEvent
  | AssetCreationFailedEvent
  | uploadCompleteEvent
  | uploadFailedEvent
  | abortUploadEvent
  | notifyAPIEvent;

// MACHINE -------------------------------------------------------------------------------------------------------------

export const isPendingUpload = (state: string): boolean => {
  return ["AwaitingUserConfirmation", "CreatingAssetRecord"].includes(state);
};
export const isUploading = (state: string): boolean => {
  return ["UploadFailed", "Done", "UploadFailed"].includes(state);
};
export const isUploaded = (state: string): boolean => {
  return state === "Uploading";
};

type Dependencies = {
  logger: Logger;
  onUpload: (asset: UploadAsset) => Promise<void>;
  onCreateAsset: (asset: UploadAsset) => Promise<void>;
  onNotifyAPI: (asset: UploadAsset) => Promise<void>;
};

export const newUploadAssetMachine = (
  deps: Dependencies,
  initialAsset: UploadAsset,
) => {
  const logger = new Named(deps.logger, "uploadAssetMachine");
  return setup({
    types: {
      context: {} as UploadAsset,
      events: {} as UploadAssetEvents,
    },
    actions: {
      log: (_, params: { message: string }) => {
        if (_LOG) logger.info(params.message);
      },
      createAssetRecord: async (_, params: { ctx: UploadAsset }) => {
        return deps.onCreateAsset(params.ctx);
      },
      initiateUpload: async (_, params: { ctx: UploadAsset }) => {
        if (_LOG) logger.info("initiateUpload");
        await deps.onUpload(params.ctx);
      },
      notifyAPI: async (_, params: { ctx: UploadAsset }) => {
        if (_LOG) logger.info("notifyAPI");
        await deps.onNotifyAPI(params.ctx);
      },
    },
    // schemas: {},
  }).createMachine({
    context: initialAsset,
    id: "assetUploadMachine",
    initial: "AwaitingUserConfirmation",
    states: {
      AwaitingUserConfirmation: {
        on: {
          "assetUploadMachine.createAsset": {
            actions: [
              assign({
                progress: () => 0,
                errorMessage: () => undefined,
              }),
              {
                type: "log",
                params: { message: "createAsset" },
              },
              {
                type: "createAssetRecord",
                params: ({ context }) => ({ ctx: context }),
              },
            ],
            target: "CreatingAssetRecord",
          },
        },
        description:
          "The system is waiting for the user to confirm the upload. No asset record is created yet.",
      },
      CreatingAssetRecord: {
        on: {
          "assetUploadMachine.assetCreated": {
            target: "Uploading",
            actions: [
              {
                type: "log",
                params: { message: "assetCreated" },
              },
              assign({
                assetID: ({ event }) => event.assetID,
                signedURL: ({ event }) => event.signedURL,
                errorMessage: () => undefined,
              }),
              {
                type: "initiateUpload",
                params: ({ context }) => ({ ctx: context }),
              },
            ],
          },
          "assetUploadMachine.assetCreationFailed": {
            target: "AssetCreationFailed",
            actions: [
              {
                type: "log",
                params: { message: "assetCreationFailed" },
              },
              assign({
                errorMessage: ({ event }) => event.errorMessage,
                progress: () => 0,
              }),
            ],
          },
        },
        description:
          "The system is creating an asset record for the file by calling the backend API.",
      },
      Uploading: {
        on: {
          "assetUploadMachine.updateProgress": {
            actions: [
              {
                type: "log",
                params: ({ context, event }) => ({
                  message: `progress ${event.progress} for ${context.assetID}`,
                }),
              },
              assign({
                progress: ({ event }) => event.progress,
                errorMessage: () => undefined,
              }),
            ],
          },
          "assetUploadMachine.uploadComplete": {
            actions: [
              assign({ progress: 1 }),
              {
                type: "notifyAPI",
                params: ({ context }) => ({ ctx: context }),
              },
            ],
            target: "Done",
          },
          "assetUploadMachine.uploadFailed": {
            actions: [
              assign({
                progress: () => 0,
                errorMessage: ({ event }) => event.errorMessage,
              }),
            ],
            target: "UploadFailed",
          },
          "assetUploadMachine.abortUpload": {
            target: "UploadAborted",
          },
        },
        description: "The file is currently being uploaded to cloud storage.",
      },
      AssetCreationFailed: {
        type: "final",
        description:
          "The asset record creation has failed. This is an end state.",
      },
      UploadFailed: {
        type: "final",
        description: "The file upload has failed. This is an end state.",
      },
      UploadAborted: {
        type: "final",
        description: "The file upload has been aborted. This is an end state.",
      },
      Done: {
        type: "final",
        description:
          "The api has been notified that the file is uploaded. This is an end state.",
      },
    },
  });
};

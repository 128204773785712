import Tag from "@tiptap/extension-mention";
import {
  SolidNodeViewRenderer,
  SolidRenderer,
  getTiptapSolidReactiveOwner,
} from "tiptap-solid";
import { PluginKey } from "prosemirror-state";
import { useWire } from "~/wire";
import { runWithOwner } from "solid-js";
import type { SuggestionProps } from "@tiptap/suggestion";
import { TagNode, TagsContainer, type TagsProps } from "./Tags.render";
import { insertCharacterCommand } from "../helpers";
import type { q } from "@repo/client";
import { stAnalytics } from "@repo/analytics";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";

export type TagsSuggestionProps = SuggestionProps<q.ControlplaneSsAsset>;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    Tag: {
      invokeTag: () => ReturnType;
    };
  }
}

// Workaround for not knowing if the slash command menu was opened by typing or manually invoked through a command
let insertTriggeredManually = false;

export const TagsExtension = Tag.extend({
  name: "tagNode",
  addAttributes() {
    return {
      id: "",
      userId: "",
      userDisplayName: "",
      projectId: "",
      organizationId: "",
      tenantId: "",
      version: 0,
      etag: "",
      originalFilename: "",
      displayName: "",
      filesize: 0,
      contentType: "",
      summary: "",
      lifecycleState: "",
      createdAt: "",
      modifiedAt: "",
    };
  },
  addNodeView() {
    return SolidNodeViewRenderer(TagNode);
  },
  addCommands: () => ({
    invokeTag: () => {
      insertTriggeredManually = true;
      return insertCharacterCommand("#");
    },
  }),
}).configure({
  suggestion: {
    pluginKey: new PluginKey("tag"),
    char: "#",
    items: async ({ query, editor }): Promise<q.ControlplaneSsAsset[]> => {
      const owner = getTiptapSolidReactiveOwner(editor);
      const wire = runWithOwner(owner, useWire);
      if (!wire) return [];

      const [assets] = wire.services.knowledge.assetsListResource;

      return (assets()?.data || []).filter(
        (item) =>
          item.displayName
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(query.toLowerCase()) ||
          item.originalFilename
            .toLowerCase()
            .replaceAll(" ", "")
            .includes(query.toLowerCase()),
      );
    },
    render: () => {
      let solidRenderer: SolidRenderer<TagsProps> | undefined;
      return {
        onStart: (props: TagsSuggestionProps) => {
          solidRenderer = new SolidRenderer(TagsContainer, {
            props,
            editor: props.editor,
          });
          solidRenderer.updateProps(props);

          const owner = getTiptapSolidReactiveOwner(props.editor);
          const wire = runWithOwner(owner, useWire);
          const from = insertTriggeredManually ? "toolbar" : "text";
          insertTriggeredManually = false;

          if (!wire) return;

          stAnalytics.track("prompt_tags_menu_opened", {
            from,
            ...getThreadEventProperties({
              workingContext:
                wire.services.identity.snapshot.context.identity.workingContext,
              threadId: wire.services.threads.snapshot.context.threadId,
              threadMessages: wire.services.threads.messages(),
            }),
          });
        },
        onUpdate(props: TagsSuggestionProps) {
          solidRenderer?.updateProps(props);
        },
        onExit() {
          solidRenderer?.destroy();
          solidRenderer = undefined;
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            solidRenderer?.destroy();
            solidRenderer = undefined;
            return true;
          }
          return false;
        },
      };
    },
  },
  renderText({ node, options }) {
    return `${options.suggestion.char}${node.attrs.displayName}`;
  },
});
